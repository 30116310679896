import "./App.css";
import "./variables.css";
import "./styles/global.css";

import Layout from "./layouts/Layout";
import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const element = document.getElementById(window.location.hash.slice(1));
    element != null && element.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
