import React, { useState, useEffect } from "react";
import Button from "../sub-components/Button.js";
import { constants } from "../constants/constants.js";
import "../styles/Navbar.css";
import "../styles/global.css";
import logo_light from "../assets/logo_light.svg";
import logo_dark from "../assets/logo_dark.svg";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
const Navbar = ({ isScroll }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-Z1PQHLH2PT");
    gtag("config", "AW-11239547880");

    const gtag_contact_report_conversion = (url) => {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-11239547880/aeDNCM6iy7MYEOjHt-8p",
        event_callback: callback,
      });
      return false;
    };

    const gtag_enquire_report_conversion = (url) => {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-11239547880/GV4ACPfTp7wYEOjHt-8p",
        event_callback: callback,
      });
      return false;
    };

    let enquireElements = document.getElementsByClassName("enquire-button");

    const handleContactEvent = () => {
      gtag_contact_report_conversion();
      // console.log("Contact");
    };
    const handleEnquireEvent = () => {
      gtag_enquire_report_conversion();
      // console.log("Enquire");
    };
    document
      .getElementById("contact-nav")
      .addEventListener("click", handleContactEvent);
    document
      .getElementById("contact-mob-nav")
      .addEventListener("click", handleContactEvent);
    document
      .getElementById("whatsapp-contact")
      .addEventListener("click", handleEnquireEvent);

    for (let i = 0; i < enquireElements.length; i++) {
      enquireElements[i].addEventListener("click", handleEnquireEvent);
    }
  }, []);

  const handleOpenMenu = () => {
    setMenuOpen((prev) => !prev);
  };
  const scrollView = (selector) => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top,
    });
  };
  return (
    <>
      <div className="nav-parent">
        <div className="d-flex justify-content-between align-items-center">
          <div className="nav-btn-container d-flex justify-content-between">
            {constants.NAVBAR_START.map((nav, index) => {
              return (
                <div
                  key={index}
                  id={`${nav}-nav`}
                  // href={`#${nav}`} //Removed due to stutter on scroll -- fallback to JS
                  onClick={() => scrollView(`#${nav}`)}
                  className="nav-links text-decoration-none"
                >
                  <span
                    className={`text-uppercase l-spacing-6 nav-links-txt f-siz18 ${
                      isScroll ? "col-black" : "col-white"
                    }`}
                  >
                    {nav}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="nav-btn-container-mob d-flex justify-content-between">
            {isScroll ? (
              menuOpen ? (
                <CloseIcon
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                  onClick={handleOpenMenu}
                />
              ) : (
                <MenuIcon
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                  }}
                  onClick={handleOpenMenu}
                />
              )
            ) : menuOpen ? (
              <CloseIcon
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  color: "#fff",
                }}
                onClick={handleOpenMenu}
              />
            ) : (
              <MenuIcon
                sx={{
                  width: "2.5rem",
                  height: "2.5rem",
                  color: "#fff",
                }}
                onClick={handleOpenMenu}
              />
            )}
          </div>
          <div className="nav-logo">
            <img
              className="m-auto nav-logo-img"
              src={isScroll ? logo_dark : logo_light}
              alt="website logo"
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="nav-btn-container">
              {constants.NAVBAR_END.map((nav, index) => {
                return (
                  <>
                    <div
                      key={index}
                      id={`${nav}-nav`}
                      // href={`#${nav}`} //Removed due to stutter on scroll -- fallback to JS
                      onClick={() => scrollView(`#${nav}`)}
                      className="nav-links text-decoration-none"
                    >
                      <span
                        className={`text-uppercase l-spacing-6 nav-links-txt f-siz18 ${
                          isScroll ? "col-black" : "col-white"
                        }`}
                      >
                        {nav}
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
            <Button
              isScroll={isScroll}
              isHeader={true}
              className="btn-nav"
              btnText={constants.ENQUIRE_NAVBAR_BTN_TXT}
              alt="enquire button"
            />
          </div>
        </div>
      </div>
      <div
        className={`nav-btn-container-mob nav_list_mob d-flex flex-column align-items-center ${
          menuOpen ? "active" : "inactive"
        }`}
      >
        {constants.NAVBAR_MOBILE.map((nav, index, arr) => {
          return (
            <>
              <div
                key={index}
                id={`${nav}-mob-nav`}
                // href={`#${nav}`} //Removed due to stutter on scroll -- fallback to JS
                onClick={() => {
                  scrollView(`#${nav}`);
                  handleOpenMenu();
                }}
                className="nav-links text-decoration-none"
              >
                <span
                  className={`text-uppercase text-center l-spacing-6 nav-links-txt f-siz18 ${
                    isScroll ? "col-black" : "col-white"
                  }`}
                >
                  {nav}
                </span>
              </div>
              {index === arr.length - 1 && (
                <Button
                  isVarientSmall={true}
                  isScroll={isScroll}
                  isMenu={true}
                  className="btn-nav"
                  btnText={constants.ENQUIRE_NAVBAR_BTN_TXT}
                  alt="enquire button"
                />
              )}
            </>
          );
        })}
      </div>
    </>
  );
};
export default Navbar;
