import React from "react";
import "../styles/about.css";
import ImageText from "../sub-components/ImageText";
import { constants } from "../constants/constants.js";

const About = () => {
  return (
    <>
      <div className="about-parent flex-column align-items-center">
        <div className="about-header f-siz64-32 m-auto">About Me</div>
        <div className="about-subHeader f-siz24-16 m-auto">
          Namaste and welcome to Zero ^ Infinity
        </div>
        <ImageText
          image={constants.ABOUT.img}
          blur={constants.ABOUT.blur}
          heading={constants.ABOUT.head}
          txt={constants.ABOUT.body}
          about={true}
        />
      </div>
    </>
  );
};
export default About;
