import serviceOne from "../assets/services-one.webp";
import serviceTwo from "../assets/services-two.webp";
import serviceThree from "../assets/services-three.webp";
import serviceFour from "../assets/services-four.webp";
import serviceOne_blur from "../assets/services-one.jpg";
import serviceTwo_blur from "../assets/services-two.jpg";
import serviceThree_blur from "../assets/services-three.jpg";
import serviceFour_blur from "../assets/services-four.jpg";
import Anand_Pashmina from "../assets/Anand_Pashmina.webp";
import Anand_Pashmina_blur from "../assets/Anand_Pashmina.jpg";

import Carousel_1 from "../assets/carousel/Carousel_1.webp";
import Carousel_2 from "../assets/carousel/Carousel_2.webp";
import Carousel_3 from "../assets/carousel/Carousel_3.webp";
import Carousel_4 from "../assets/carousel/Carousel_4.webp";
import Carousel_5 from "../assets/carousel/Carousel_5.webp";
import Carousel_6 from "../assets/carousel/Carousel_6.webp";
import Carousel_7 from "../assets/carousel/Carousel_7.webp";
import Carousel_8 from "../assets/carousel/Carousel_8.webp";
import Carousel_9 from "../assets/carousel/Carousel_9.webp";
import Carousel_10 from "../assets/carousel/Carousel_10.webp";
import Carousel_11 from "../assets/carousel/Carousel_11.webp";
import Carousel_12 from "../assets/carousel/Carousel_12.webp";
import Carousel_13 from "../assets/carousel/Carousel_13.webp";
import Carousel_14 from "../assets/carousel/Carousel_14.webp";
import Carousel_15 from "../assets/carousel/Carousel_15.webp";
import Carousel_2_sm from "../assets/carousel/Carousel_2.jpg";
import Carousel_1_sm from "../assets/carousel/Carousel_1.jpg";
import Carousel_3_sm from "../assets/carousel/Carousel_3.jpg";
import Carousel_4_sm from "../assets/carousel/Carousel_4.jpg";
import Carousel_5_sm from "../assets/carousel/Carousel_5.jpg";
import Carousel_6_sm from "../assets/carousel/Carousel_6.jpg";
import Carousel_7_sm from "../assets/carousel/Carousel_7.jpg";
import Carousel_8_sm from "../assets/carousel/Carousel_8.jpg";
import Carousel_9_sm from "../assets/carousel/Carousel_9.jpg";
import Carousel_10_sm from "../assets/carousel/Carousel_10.jpg";
import Carousel_11_sm from "../assets/carousel/Carousel_11.jpg";
import Carousel_12_sm from "../assets/carousel/Carousel_12.jpg";
import Carousel_13_sm from "../assets/carousel/Carousel_13.jpg";
import Carousel_14_sm from "../assets/carousel/Carousel_14.jpg";
import Carousel_15_sm from "../assets/carousel/Carousel_15.jpg";

export const constants = {
  NAVBAR_MOBILE: ["home", "services", "about", "contact"],
  NAVBAR_START: ["home", "services", "about"],
  NAVBAR_END: ["contact"],
  MOBILE_WIDTH: 891,
  ENQUIRE_NAVBAR_BTN_TXT: "Enquire",
  ENQUIRE_BTN_TXT: "Enquire Now",
  CONTACT: {
    phone: "+91 86230 98894",
    social: "@zeroinfinitydivination",
    socialLink: "https://www.instagram.com/zeroinfinitydivination/",
    email: "zeroinfinitydivination@gmail.com",
    whatsApp: "https://wa.me/918623098894",
  },
  SERVICES: [
    {
      img: serviceOne,
      blur: serviceOne_blur,
      head: "Individual Reading",
      body: "This reading is open in nature. We follow the reading where it goes and simultaneously take up the client's questions. This can include anything at all and is not just specific to personal or professional life. The range can go anywhere from the past, in the present or into the future. Ask anything, no question is considered small or too big or insignificant.",
    },
    {
      img: serviceTwo,
      blur: serviceTwo_blur,
      head: "Couple Reading",
      body: "This reading is one of my gifts as a Reader and is focused on a couple together by respecting their individual identities. Family oriented, business partnerships with your significant other or anything else in the union that the couple wishes to uncover is brought to light here.",
    },
    {
      img: serviceThree,
      blur: serviceThree_blur,
      head: "Individual Business Reading",
      body: "This reading is focused solely on the profession or the business of a client. This will be the core of the reading surrounded by the many factors that might strengthen or weaken it. We will shed light on the possibilities of bringing great health to your business or profession through gaining clarity and purpose around it as a whole.",
    },
    {
      img: serviceFour,
      blur: serviceFour_blur,
      head: "Business Team Reading",
      body: "This reading is made to integrate not only a team working together but also all the various aspects of a business blending into a bigger picture. However small or big a business might be, it is one common thread that brings purpose to all working towards it making it powerful enough to bring a positive change.",
    },
  ],
  ABOUT: {
    img: Anand_Pashmina,
    blur: Anand_Pashmina_blur,
    head: "Hi, I'm Anand Pashmina!",
    body: {
      body_1:
        "I'm a Tarot Reader and Psychic. I began my ever-enchanting journey with Tarot after 19 years of realizing I was walking a spiritual path and after 9 years of extensive meditation and soul searching.",
      body_2:
        "I'm here to guide you through my in-depth and intuitive Tarot Readings to get insight, clarity, solutions, important messages and absolutely anything you need to know in your Readings (most readings end up being therapeutic sessions as well!)",
      body_3:
        "My readings are precise. I try to simplify them as much as possible and take you through it step by step. I do not rush with conclusions and I ask questions to get in tune with your energy. My approach is a combination of compassion and truth so that I am able to give you an insight and understanding into your situation. You can expect a calm and detailed reading.",
      body_4:
        "I am ever so grateful for this wonderful opportunity to be a channel and a medium with clairvoyant, clairsentient, clairaudient and claircognizant gifts so that I can bring all of this into my readings.",
      body_5:
        "I boast and cherish a clientele which is an inclusive list of interesting and lovely people from all ages, backgrounds, ethnicities, mainstream & alternate professions... and the list goes on. Would be an absolute blessing to bring guidance through my Tarot Readings in your journey on this beautiful planet.",
      body_6: "Let's walk through this together! ",
      body_7: "Love and light ",
    },
  },
  CAROUSEL: [
    {
      blur: Carousel_1_sm,
      real: Carousel_1,
    },
    {
      blur: Carousel_2_sm,
      real: Carousel_2,
    },
    {
      blur: Carousel_3_sm,
      real: Carousel_3,
    },
    {
      blur: Carousel_4_sm,
      real: Carousel_4,
    },
    {
      blur: Carousel_5_sm,
      real: Carousel_5,
    },
    {
      blur: Carousel_6_sm,
      real: Carousel_6,
    },
    {
      blur: Carousel_7_sm,
      real: Carousel_7,
    },
    {
      blur: Carousel_8_sm,
      real: Carousel_8,
    },
    {
      blur: Carousel_9_sm,
      real: Carousel_9,
    },
    {
      blur: Carousel_10_sm,
      real: Carousel_10,
    },
    {
      blur: Carousel_11_sm,
      real: Carousel_11,
    },
    {
      blur: Carousel_12_sm,
      real: Carousel_12,
    },
    {
      blur: Carousel_13_sm,
      real: Carousel_13,
    },
    {
      blur: Carousel_14_sm,
      real: Carousel_14,
    },
    {
      blur: Carousel_15_sm,
      real: Carousel_15,
    },
  ],
};
