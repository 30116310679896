import React from "react";
import { constants } from "../constants/constants.js";
import "../styles/global.css";
import "../styles/button.css";

const Button = ({
  isScroll,
  isVarientSmall = false,
  btnText = constants.ENQUIRE_BTN_TXT,
  btnLink = constants.CONTACT.email,
  isFooter = false,
  isHeader = false,
  isMenu = false,
}) => {
  return (
    <button
      className={`text-decoration-none enquire-button col-black btn-container btn-container-nav ${
        isHeader && `btn-display-mobile-none`
      } ${isVarientSmall && `btn-padding-mobile`} ${
        isFooter && `btn-container-footer`
      } ${
        ((isHeader && !isScroll) || (isMenu && !isScroll)) &&
        `btn-container-footer`
      }`}
      onClick={() => (window.location.href = `mailto:${btnLink}`)}
      aria-roledescription="button"
    >
      <span className="text-uppercase l-spacing-6 f-siz18-14">{btnText}</span>
    </button>
  );
};
export default Button;
