import React from "react";
import "../styles/services.css";
import ImageText from "../sub-components/ImageText";
import { constants } from "../constants/constants.js";

const Services = () => {
  return (
    <div className="services-parent flex-column align-items-center">
      <div className="services-header f-siz64-32 m-auto">My Services</div>
      {constants.SERVICES.map((service, index) => {
        return (
          <ImageText
            key={index}
            col={index % 2}
            image={service.img}
            blur={service.blur}
            heading={service.head}
            txt={service.body}
          />
        );
      })}
    </div>
  );
};
export default Services;
