import React from "react";
import "../styles/footer.css";
import "../styles/global.css";
import Button from "../sub-components/Button";
import { constants } from "../constants/constants.js";
import eight_dark from "../assets/eight_dark.svg";
import logo_dark from "../assets/logo_dark.svg";

const Footer = () => {
  return (
    <div className="footer-parent d-flex flex-column align-items-center">
      <div className="ftr-sec-1 d-flex flex-column align-items-center">
        <img src={eight_dark} alt="eight" className="ftr-eght" loading="lazy" />
        <img src={logo_dark} alt="eight" className="ftr-logo" loading="lazy" />
        <div className="f-siz22-14 ftr-slogan col-black mt-2">
          Heal <span className="divider-footer-margins">|</span> Elevate{" "}
          <span className="divider-footer-margins">|</span> Ascend
        </div>
      </div>
      <div className="ftr-sec-2 d-flex flex-column align-items-center">
        <div className="f-siz32 ftr-contact col-black mt-2">Contact Me</div>

        <div className="f-siz16 f-wt400  mt-2">
          <a
            href={`mailto:${constants.CONTACT.email}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Contact us through email"
          >
            {constants.CONTACT.email}
          </a>
        </div>

        <div className="f-siz16 f-wt400  mt-2">
          <a
            href={constants.CONTACT.socialLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Contact us through instagram"
          >
            {constants.CONTACT.social}
          </a>
        </div>
        {/* 
        <div className="f-siz16 f-wt400  mt-2">
          <a
            href={constants.CONTACT.whatsApp}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Contact us through whatsApp"
          >
            +91 86230 98894
          </a>
        </div> */}
      </div>
      <div className="ftr-btn mt-2">
        <Button isFooter />
      </div>
    </div>
  );
};
export default Footer;
