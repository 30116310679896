import React, { useEffect, useState, useRef } from "react";
import "../styles/Home.css";
import "../styles/global.css";
import headerVid from "../assets/video/headerBg.mp4";
import headerVid_mob from "../assets/video/headerBg_mob.mp4";
import headerVid_ogv from "../assets/video/headerBg.ogv";
import headerVid_webm from "../assets/video/headerBg.webm";
import home_eight from "../assets/hero_8.svg";
import home_placeholder from "../assets/homePlaceholder.webp";
import home_placeholder_mob from "../assets/home_placeholder_mob.webp";

const Home = ({ isSiteLoaded }) => {
  const videoRef = useRef(null);
  const handleEnd = () => {
    videoRef.current.pause();
    videoRef.currentTime = 0;
    videoRef.current.load();
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 591) {
      setIsMobile(true);
    }
  }, []);

  return (
    <div className="home-parent">
      <video
        id="home-video"
        ref={videoRef}
        onEnded={handleEnd}
        playsInline
        autoPlay
        loop
        muted={true}
        alt="tarot card reader"
        poster={isMobile ? home_placeholder_mob : home_placeholder}
        src={isMobile ? headerVid_mob : headerVid}
        className="home-video-container"
      >
        {isSiteLoaded && (
          <>
            <source
              src={isMobile ? headerVid_mob : headerVid}
              type="video/mp4"
            />
            <source
              src={isMobile ? headerVid_mob : headerVid_webm}
              type="video/webm"
            />
            <source
              src={isMobile ? headerVid_mob : headerVid_ogv}
              type="video/ogg"
            />
          </>
        )}
        <img
          src={home_placeholder}
          title="Your browser does not support the <video> tag"
          alt="home background"
        />
      </video>
      <div className="d-flex flex-column align-items-center home-asset-container">
        <img src={home_eight} alt="eight" className="home-eight" />
        <div className="f-siz22-14 col-white mt-2 home-text-shadow">
          Tarot Reader <span className="divider-header-margins">|</span> Psychic
        </div>
      </div>
    </div>
  );
};
export default Home;
