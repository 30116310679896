import React, { useRef } from "react";
import "../styles/quoteBottom.css";
import quoteVid from "../assets/video/quoteVid.mp4";
import quoteVid_ogv from "../assets/video/quoteVid.ogv";
import quoteVid_webm from "../assets/video/quoteVid.webm";
import quote_placeholder from "../assets/quoteBottom.webp";

const QuoteBottom = ({ isSiteLoaded }) => {
  const quoteVideoRef = useRef(null);
  const handleVideoEnd = () => {
    quoteVideoRef.current.pause();
    quoteVideoRef.currentTime = 0;
    quoteVideoRef.current.load();
  };
  return (
    <div className="mt-1 mb-1 quote-bottom d-flex flex-column">
      <div className="quote-vid-parent">
        <video
          ref={quoteVideoRef}
          onEnded={handleVideoEnd}
          playsInline
          autoPlay
          loop
          muted={true}
          alt="tarot card reader"
          poster={quote_placeholder}
          src={quoteVid}
          className="quote-video-container"
        >
          {isSiteLoaded && (
            <>
              <source src={quoteVid} type="video/mp4" />
              <source src={quoteVid_webm} type="video/webm" />
              <source src={quoteVid_ogv} type="video/ogg" />
            </>
          )}
          <img
            src={quote_placeholder}
            title="Your browser does not support the <video> tag"
            alt="quote bottom background"
          />
        </video>
      </div>
    </div>
  );
};
export default QuoteBottom;
