import React from "react";
import "../styles/imgtxt.css";
import "../styles/global.css";
import Button from "./Button";
import stars from "../assets/stars.svg";
const ImageText = ({
  col = false,
  image,
  heading,
  txt,
  about = false,
  blur,
}) => {
  return (
    <>
      <div
        className={`img-txt-container col-gap-10 justify-content-between ${
          col ? `flex-row-reverse` : `flex-row`
        } ${about ? `pb-none` : ``}`}
      >
        {/* <div
          className="blurred-img"
          style={{ background: `url(${blur}) no-repeat center center/cover` }}
        > */}
        <img
          src={image}
          className={about ? "about-img-container" : "service-img-container"}
          style={
            {
              // background: `url(${image}) no-repeat center center/cover`,
            }
          }
          alt="services"
        />
        {/* </div> */}
        <div className="service-text">
          <div className="service-head">{heading}</div>
          {about ? (
            <>
              <div className="service-body pb-1_5 f-siz18-14">{txt.body_1}</div>
              <div className="service-body pb-1_5 f-siz18-14">{txt.body_2}</div>
              <div className="service-body pb-1_5 f-siz18-14">{txt.body_3}</div>
            </>
          ) : (
            <div className="service-body f-siz18-14">{txt}</div>
          )}
          {!about && (
            <div className="service-button">
              <Button />
            </div>
          )}
        </div>
      </div>
      {about && (
        <div className="add-txt-container">
          <div className="service-body pb-1_5 f-siz18-14">{txt.body_4}</div>
          <div className="service-body pb-1_5 f-siz18-14">{txt.body_5}</div>
          <div className="service-body pb-1_5 f-siz18-14">
            {txt.body_6}
            <p className="love_shine">
              {txt.body_7}
              <img
                style={{ display: "inline" }}
                src={stars}
                alt="sparkle stars"
                loading="lazy"
              />
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default ImageText;
