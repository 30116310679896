import React from "react";
import { constants } from "../constants/constants.js";
import "../styles/carousel.css";
import "../styles/global.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  threek: {
    breakpoint: { max: 5000, min: 2000 },
    items: 7,
  },
  twok: {
    breakpoint: { max: 2000, min: 1500 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function CarouselComponent() {
  // function handleBlurredImageDiv(lastIndex) {
  //   const blurredImageDiv = document.querySelectorAll(".blurred-img");
  //   function loaded(selectDiv) {
  //     selectDiv.classList.add("loaded");
  //   }
  //   blurredImageDiv.forEach((div) => {
  //     let img = div.querySelector("img");
  //     if (img.complete) {
  //       loaded(div);
  //     } else {
  //       img.addEventListener("load", loaded(div));
  //     }
  //   });
  // }
  return (
    <div className="mt-1 mb-1 carousel-parent">
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={500}
        arrows={false}
      >
        {constants.CAROUSEL.map((item, index) => {
          return (
            // <div
            //   className="blurred-img"
            //   key={index}
            //   style={{
            //     backgroundImage: `url(${item.blur})`,
            //   }}
            //   onLoad={() => {
            //     if (index === constants.CAROUSEL.length - 1) {
            //       handleBlurredImageDiv();
            //     }
            //   }}
            // >
            <img
              key={index}
              src={item.real}
              alt="carousel"
              className="carousel-img"
              loading="lazy"
              width="500"
              height="300"
            />
            // </div>
          );
        })}
      </Carousel>
    </div>
  );
}
