import React from "react";
import "../styles/quoteOne.css";

const QuoteTop = () => {
  return (
    <div className="mt-1 mb-1 quote-one">
      <p className="quote-text-top f-siz44-24 m-auto text-center">
        Tarot is a great way to get clarity, insight, guidance & direction
        within your self or into any situation in your life.
      </p>
    </div>
  );
};
export default QuoteTop;
